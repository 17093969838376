import logo from './logo.svg';
import './App.css';
import Home from './Home';
import Signup from './Signup';
import Dashboard from './Dashboard';
import SetPassword from './SetPassword';
import ValidateEmailAddress from './ValidateEmailAddress'
import DashboardMain from './DashboardMain';
import Employees from './Employees';
import Payment from './Payment';
import Login from './Login';
import Loading from './Loading';
import ResetPasswordVerification from './ResetPasswordVerification';
import ResetPassword from './ResetPassword'

import { Routes, Route} from 'react-router-dom';


function App() {

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} /> 
        <Route exact path="/:id" element={<Home />} />
        <Route exact path="/signup" element={<Signup />} /> 
        <Route exact path="/dashboard" element={<Dashboard />} >
        <Route exact  path="/dashboard/employees" element={<Employees />} />
          <Route exact index element={<DashboardMain />} />
        </Route>
        <Route exact path="/setpassword" element={<SetPassword />} />
        <Route exact path="/validateemailaddress" element={<ValidateEmailAddress />} />
        <Route exact path="/dashboardmain" element={<DashboardMain />} />
        <Route exact path="/payment" element={<Payment />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/reset-password-verification" element={<ResetPasswordVerification />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/loading" element={<Loading />} />
        
        </Routes>
    </>
  );
}

export default App;
