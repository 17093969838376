import { Link, useNavigate } from 'react-router-dom';
import "./App.css";
import NavBar from './NavBar';

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from 'react';
import URL from './config.js';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { Container } from '@mui/material'
import Footer from './Footer.js'

function ResetPasswordVerification(props) {
    const [open, setOpen] = useState(false)
    const [v_ResetEmail, set_v_ResetEmail] = useState(localStorage.getItem('user-email:show'));
    const [ResetEmailValid, setResetEmailValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usernotfound, setusernotfound] = useState(false);
    const [Response, setResponse] = useState(false)


    useEffect(() => {
        document.title = "Sign in - GenieSign - Email Signature Generator";
            ResetEmailVerification({target: {value: v_ResetEmail}});
    }, []);


    {/*-------- Password Save to DB ------------*/ }

    const ResetPassword = async () => {
        const result = await URL.post('/emailexist',
            {
                u_VerificationEmail: v_ResetEmail,
            });

        if (result.data.Message === "Matched") {
            setResponse(true);
            localStorage.clear("token");

        }
        else
        {
            setResponse(true);
            localStorage.clear("token");
        }
    }

    {/*-------- Password Save to DB ------------*/ }

    {/*-------- Email Validation Start------------*/ }



    const ResetEmailVerification = async (e) => {
        const { value } = e.target;
        set_v_ResetEmail(value);

        const validateEmail = (e) => {
            const regEx = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+\@[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,3}$/g);
            return regEx.test(e);
        };

        const EmailValidation = validateEmail(e.target.value);
        setResetEmailValid(EmailValidation);

    }
    {/*-------- Email Validation End------------*/ }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Container className="page-container">
                <AppBar position="absolute">
                    <Toolbar variant="dense" style={{ backgroundColor: '#434040', justifyContent: 'flex-end', padding: '0px 50px 0px 50px' }} >
                        <a color="inherit" href="mailto:support@geniesign.io" component={Link} class='ToolbarBtnEmail'>
                            <img src="images/Email.png" className='ToolbarImg' />
                            support@geniesign.io
                        </a>
                    </Toolbar>
                </AppBar>

                {/*Header Code Reference Navbar.JS */}
                <NavBar />
                {/*Header Code Reference Navbar.JS */}

                
                
                {
                    Response === true ? 
                    <>
                    <div className="SP-Form-Main">
                            <Typography className="SP-Container-Main">
                                Reset Password
                            </Typography>
                            <Typography className="SP-Container-Text" >
                                If the address you typed matches one of our accounts, we’ll send you an email with instructions to reset your password.
                                </Typography>
                                <Button className='SP-Btn' href='/login' style={{marginTop: 30}}>
                                Company Login
                                </Button>
                            
                            
                            </div>
                                      
                    </>
                    :
                    <>
                     <div className="SP-Form-Main">
                            <Typography className="SP-Container-Main">
                                Reset Password
                            </Typography>
                    <Typography className="SP-Container-Text">
                    Enter your email address below
                    </Typography>
                    </div>
                    <div className="SP-Center-Main">
                    <Paper className="SP-Center-TextField" elevation={0} style={{ height: 10, width: 200, marginTop: 0 }} >
                    </Paper>
                        <TextField
                            variant="outlined"
                            size='small'
                            className={
                            v_ResetEmail === '' || v_ResetEmail === null && 'valid' ||  
                            ResetEmailValid === false && 'invalid'
                    }
                FormHelperTextProps={{style: {color: 'red', height: '40px', marginLeft: '0px'}}}
                InputProps={{
                    style: {
                        height: '40px',
                        width: '100%',
                        fontSize: '12pt'
                    }
                }}
                InputLabelProps={{
                   shrink:true, 
                    style: {
                        fontSize: '11pt',
                        verticalAlign: 'center'
                    }
                }}

                
                type="text"
                label={"Email"}
                value={v_ResetEmail}
                onChange={ResetEmailVerification}
                helperText={
                    v_ResetEmail === '' || v_ResetEmail === null ? null 
                    :ResetEmailValid === true?
                    ""
                    : "Enter a valid email"
                }
            >
            </TextField>
            <Paper className="SP-Center-TextField" elevation={0} style={{ height: 80, width: 200, marginTop: 0 }}>
                </Paper>
                <Button className='SP-Btn'
                    disabled={
                        v_ResetEmail === '' || ResetEmailValid === false || loading
                    } onClick={ResetPassword}>
                    Reset Password
                </Button>
                
                </div>
                
            </>
                }




            </Container>
            {/*Footer Code Reference Footer.js */}
            <Footer />
            {/*Footer Code Reference Footer.js */}
        </div>
    );
}
export default ResetPasswordVerification;