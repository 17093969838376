import React from 'react'
import Button from '@mui/material/Button'
import classes from './RedButton.module.css'
import CircularProgress from '@mui/material/CircularProgress';

const RedButton = (props) => {

	return (
		// <Button className={`${classes.LGBtn}`} disabled={props.loading || props.fempty}
		//              onClick={props.fg}>
		//             {props.text}
		//             {props.loading && (
		// 			              <CircularProgress
		// 				            size={25}
		// 				            sx={{
		// 					          color: '#414042',
		// 					          position: 'absolute',
		// 					          top: '20%',
		// 					          right: '45%',
		// 					          zIndex: 1,
		// 				        }}
		// 			       />
		// 		        )}
		//         </Button>
		<Button
			className={`${classes.LGBtn} ${props.CustomClass}`}
			href={props.hreflink}
			onClick={props.onclickfunc}
			disabled={props.disabled}
			startIcon={props.Icon}>
			{props.text}
		</Button>

	);

}
export default RedButton;