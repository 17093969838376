import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios'

import { Box, CircularProgress, Container, InputAdornment, Tooltip, Fab, Snackbar, Alert, Button, Typography, Paper, AppBar, Toolbar, TextField, Table, TableBody, TableCell, TableContainer, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Link } from '@mui/material';


import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { green } from '@mui/material/colors';
import { OutlookIcon, Office365Icon, GmailIcon, AppleMailIcon } from './customsvg.js'
import ImageSelector from './Components/CompanyLogoSelector/ImageSelector.js';

import URL from './config.js'
import "./App.css";
import NavBarHome from './NavBarHome';
import Footer from './Footer'



const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const SelectLogoModalstyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

function Home() {

	const [open, setOpen] = useState(false);
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [v_FullName, set_v_FullName] = useState('');

	const [v_ITManagerEmail, set_v_ITManagerEmail] = useState();
	const [cookies, setCookie] = useCookies(['user']);
	const [ip, setIP] = useState('');
	const [iplocation, setIPLocation] = useState('');
	const [URLValid, setURLValid] = useState(null);

	const [NameValid, setNameValid] = useState(null);
	const [NameLengthValid, setNameLengthValid] = useState(true);

	const [v_PositionTitle, set_v_PositionTitle] = useState('');
	const [PositionTitleValid, setPositionTitleValid] = useState(null);
	const [PositionTitleLengthValid, setPositionTitleLengthValid] = useState(true);

	const [v_LineNumber, set_v_LineNumber] = useState('');
	const [PhoneNumberLengthValid, setPhoneNumberLengthValid] = useState(true);

	const [v_Extension, set_v_Extension] = useState('');
	const [ExtensionLengthValid, setExtensionLengthValid] = useState(true);

	const [v_MobileNumber, set_v_MobileNumber] = useState('');
	const [MobileNumberLengthValid, setMobileNumberLengthValid] = useState(true);

	const [v_Email, set_v_Email] = useState('');
	const [EmailLengthValid, setEmailLengthValid] = useState(true);

	const [v_CompanyName, set_v_CompanyName] = useState('');
	const [CompanyNameLengthValid, setCompanyNameLengthValid] = useState(true);

	const [v_CompanyUrl, set_v_CompanyUrl] = useState('');
	const [CompanyUrlLengthValid, setCompanyUrlLengthValid] = useState(true);

	const [v_CompanyLogoUrl, set_v_CompanyLogoUrl] = useState('');
	const [CompanyLogoUrlLengthValid, setCompanyLogoUrlLengthValid] = useState(true);


	const [URLMatch, setURLMatch] = useState(true);

	const [UserEmailValid, setUserEmailValid] = useState('');
	const [FetchingURLValid, setFetchingURLValid] = useState('');
	const [CompanyNameValid, setCompanyNameValid] = useState(null);
	const [ITEmailValid, setITEmailValid] = useState(null);
	const [rtl, setrtl] = useState('');
	const [CNCLURLResponse, SetCNCLURLResponse] = useState('');


	let AURL = '';
	let lang = 'EN';
	const timer = useRef();

	useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	const handleClickOpen = () => {

	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleConfirmationClose = () => {
		setOpenConfirmation(false);
	};

	const pathname = window.location.pathname;
	const CURL = pathname.split("/")[1]

	useEffect(() => {
		document.title = "GenieSign - Email Signature Generator";
		set_v_CompanyUrl(CURL)
		FetchCNCLURL(CURL)
	}, [CURL]);

	{/*Client IP and Other Details Fetch*/ }

	const getData = async () => {
		const res = await axios.get('https://geolocation-db.com/json/')
		setIP(res.data.IPv4)
		setIPLocation(res.data.country_name)
	}

	useEffect(() => {
		//passing getData method to the lifecycle method
		getData()
	}, [])

	{/*Client IP and Other Details Fetch*/ }


	{/* Copy Signature and Limit Exceed PopUp */ }
	let generate = null;
	let type = '';

	const Copy = async () => {

		if (rtl === '') {
			lang = 'EN'
		}
		else {
			lang = 'AR'
		}

		if (!loading) {
			setLoading(true);
			setSuccess(false);
			const response = await URL.post('/limitresponse',
				{
					u_resEmail: v_Email,
					u_resCompanyURL: v_CompanyUrl

				});
			generate = response.data.Generate
			type = response.data.Type
			if (response.data.Message === "Allow") {

				saveSignatureToDB();

				//---Copy Signature---//
				var doc = document,
					text = doc.getElementById("theSign"),
					range,
					selection;
				text.style.border = 0

				if (doc.body.createTextRange) {
					range = doc.body.createTextRange();
					range.moveToElementText(text);
					range.select();
				}
				else if (window.getSelection) {
					selection = window.getSelection();
					range = doc.createRange();
					range.selectNodeContents(text);
					selection.removeAllRanges();
					selection.addRange(range);
				}
				document.execCommand("copy");
				window.getSelection().removeAllRanges();
				//---Copy Signature---//

				timer.current = window.setTimeout(() => {
					setSuccess(true);
					setLoading(false);
				}, 2000);

				timer.current = window.setTimeout(() => {
					setSuccess(false);
					setLoading(false);
				}, 4000);
			}
			else if (response.data.Message === "Not Allow") {
				saveSignatureToDB();
				const result = await URL.post('/requestedemail', {
					u_UserCompanyURL: v_CompanyUrl,
				})
				set_v_ITManagerEmail(result.data)
				setOpen(true);
				setLoading(false);
			}
		}
	}

	{/* Copy Signature and Limit Exceed PopUp */ }


	{/* --------------------------------------------------------------------------------*/ }

	const saveSignatureToDB = async () => {
		const result = await URL.post('/signaturecreate',
			{
				u_FullName: v_FullName,
				u_PositionTitle: v_PositionTitle,
				u_LineNumber: v_LineNumber,
				u_Extension: v_Extension,
				u_MobileNumber: v_MobileNumber,
				u_Email: v_Email,
				u_CompanyName: v_CompanyName,
				u_CompanyUrl: v_CompanyUrl,
				u_CompanyLogoUrl: v_CompanyLogoUrl,
				u_SignatureGenerated: generate,
				u_signIP: ip,
				u_signIPLocation: iplocation,
				u_subtype: type,
				u_rtl: rtl,
				u_lang: lang,
			})
		if (result.data === "Data Saved" && generate === true && type === "Paid") {
			subscriptionUpdate();
		}
		else {

		}

	}

	const subscriptionUpdate = () => {
		URL.post('/updatesubs',
			{
				u_subCompanyURL: v_CompanyUrl,
				u_Lang: lang,
			})
	}

	const EmailSent = () => {
		URL.post('/ITEmail',
			{
				u_ITManagerEmail: v_ITManagerEmail,
				u_SignFullName: v_FullName,
				u_SignCompanyName: v_CompanyName,
				u_SignCompanyURL: v_CompanyUrl,
				u_SignUserEmail: v_Email,
				u_RequestIP: ip,
				u_RequestIPLocation: iplocation
			}).catch((error) => {
				console.log(error);
			});
		handleClose();
		set_v_ITManagerEmail('')
		setOpenConfirmation(true);
	}

	const FetchCNCLURL = async (AURL) => {
		const response = await URL.post('/cdcl',
			{
				u_CompanyChange: AURL,
				u_CompanyLanguage: lang
			}
		)
		if (response.data.Message === "Matched") {
			SetCNCLURLResponse("Matched")
			if(validateCompanyName(response.data.DBCN)){
				setCompanyNameValid(true);
				set_v_CompanyName(response.data.DBCN)	
				set_v_CompanyLogoUrl(response.data.DBCLURL)			
			}
			
		}
		else {
			SetCNCLURLResponse('')
			set_v_CompanyName('')
			set_v_CompanyLogoUrl('')
		}
	}

	{/* ----------------------------------VALIDATIONS START--------------------------------------------*/ }

	{/*-------- URL Validation Start------------*/ }

	const URLChange = async (e) => {
		if (e.target.value.length > 256) {
			setCompanyUrlLengthValid(false);
		}
		else {
			setCompanyUrlLengthValid(true);
		}

		const { value } = e.target;
		set_v_CompanyUrl(value);

		const validateUrl = (e) => {
			const regEx = new RegExp(/^[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,5}$/g);
			return regEx.test(e);
		};

		const URLChecked = validateUrl(e.target.value);
		setURLValid(URLChecked);
	};

	{/*-------- URL Validation Start------------*/ }

	const validateFullName = (e) => {
		let regEx;
		if (rtl === 'rtl') {
			// Arabic characters fall in the Unicode range 0600 - 06FF (\u0600-\u06FF)
			regEx = new RegExp(/^[\u00C0-\u024F\u1E00-\u1EFF\u0600-\u06FF\s\p{P}]/);
		}
		else if (rtl !== 'rtl') {
			regEx = new RegExp(/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\s\p{P}]*$/);
		}
		return regEx.test(e);
	}
	const validateCompanyName = (e) => {
		let regEx
		regEx = new RegExp(/^[0-9a-zA-Z\u0600-\u06FF\s\p{P}.&-]*$/);
		return regEx.test(e);
	}
	{/*-------- Full Name Validation Start------------*/ }

	const FullNameChange = (e) => {
		if (e.target.value.length > 256) {
			setNameLengthValid(false);
		}
		else {
			setNameLengthValid(true);
		}
		const { value } = e.target;
		set_v_FullName(value);
		const result = validateFullName(e.target.value);
		setNameValid(result)
	};


	{/*-------- Full Name Validation End------------*/ }

	{/*-------- Position Title Validation Start------------*/ }

	const PositionTitleChange = (e) => {
		if (e.target.value.length > 256) {
			setPositionTitleLengthValid(false)
		}
		else {
			setPositionTitleLengthValid(true)
		}
		set_v_PositionTitle(e.target.value)
		const result = validateFullName(e.target.value); //Line 282-289
		setPositionTitleValid(result)
	};

	{/*-------- Position Title Validation End------------*/ }

	{/*-------- Line Number Validation Start------------*/ }

	const LineNumberChange = (e) => {
		if (e.target.value.length > 12) {
			setPhoneNumberLengthValid(false)
		}
		else {
			setPhoneNumberLengthValid(true)
		}
		const result = e.target.value.replace(/[^0-9-()]/gi, '');
		set_v_LineNumber(result)
	};

	{/*-------- Line Number Validation End------------*/ }

	{/*-------- Extension Validation Start------------*/ }

	const ExtensionChange = (e) => {
		if (e.target.value.length > 5) {
			setExtensionLengthValid(false);
		}
		else {
			setExtensionLengthValid(true);
		}
		const result = e.target.value.replace(/[^0-9]/gi, '');
		set_v_Extension(result)
	};

	{/*-------- Extension Validation End------------*/ }

	{/*-------- Mobile Number Validation Start------------*/ }

	const MobileNumberChange = (e) => {
		if (e.target.value.length > 12) {
			setMobileNumberLengthValid(false)
		}
		else {
			setMobileNumberLengthValid(true);
		}
		const result = e.target.value.replace(/[^0-9-+ " "]/gi, '');
		set_v_MobileNumber(result)
	};

	{/*-------- Mobile Number Validation End------------*/ }

	{/*-------- Email Validation Start------------*/ }

	const typingTimer = useRef();

	const EmailChange = (e) => {
		const { value } = e.target;
		set_v_Email(value.toLowerCase());

		let EV = false
		let UV = false

		// Validating Email, Getting Company URL from the Email and Validating Fetched Company whether it's true or not
		const validateEmail = (e) => {
			const regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+\@[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,3}$/g;
			return regEx.test(e);
		}
		EV = validateEmail(e.target.value.toLowerCase());
		setUserEmailValid(EV);
		AURL = e.target.value.substring(
			e.target.value.indexOf("@") + 1,
			e.target.value.lastIndexOf("")
		)

		const FetchingValidateURL = (AURL) => {
			const regEx = /^[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,5}$/g;
			return regEx.test(AURL);
		};
		UV = FetchingValidateURL(AURL);
		setFetchingURLValid(UV);

		clearTimeout(typingTimer.current);
		typingTimer.current = setTimeout(() => {
			EmailChangefunc(e, EV, UV, CURL);
		}, 1500);
	}

	const EmailChangefunc = async (e, EV, UV, CURL) => {
		if (EV === true && UV === true && CURL === '') {
			if (rtl === '') {
				lang = 'EN'
			}
			else {
				lang = 'AR'
			}
			set_v_CompanyUrl(AURL)
			await FetchCNCLURL(AURL)
		}
		else if (CURL !== '') {
			if (AURL === CURL) {
				setURLMatch(true)
			}
			else {
				setURLMatch(false)
			}

		}
		else {
			set_v_CompanyName('')
			set_v_CompanyLogoUrl('')
			set_v_CompanyUrl('')
		}

		if (e.target.value.length > 256) {
			setEmailLengthValid(false)
		}
		else {
			setEmailLengthValid(true)
		}

	}

	{/*-------- Email Validation End------------*/ }


	{/*-------- Company Name Validation Start------------*/ }


	const CompanyChange = async (e) => {
		if (e.target.value.length > 256) {
			setCompanyNameLengthValid(false);
		}
		else {
			setCompanyNameLengthValid(true);
		}

		set_v_CompanyName(e.target.value);
		const result = validateCompanyName(e.target.value) //Line 282-289
		setCompanyNameValid(result);

	};
	{/*-------- Company Name Validation End------------*/ }

	{/*-------- IT Manager Validation Start------------*/ }

	const ITEmailChange = async (e) => {
		const { value } = e.target;
		set_v_ITManagerEmail(value.toLowerCase());

		const validateEmail = (e) => {
			const regEx = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+\@[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,3}$/g);
			return regEx.test(e);
		};

		if (v_ITManagerEmail != null) {
			const ITEmailCheck = validateEmail(e.target.value.toLowerCase());
			setITEmailValid(ITEmailCheck);
		}
	};

	{/*-------- IT Manager Validation End------------*/ }


	{/* ----------------------------------VALIDATIONS END--------------------------------------------*/ }


	{/* --------------------------------------------------------------------------------*/ }

	const selectedImageFunc = (url) => {
		set_v_CompanyLogoUrl(url);
	};
	return (
		<div style={{ width: '100%', height: '100%' }}>
			<Container className="page-container">
				<AppBar position="absolute">
					<Toolbar variant="dense" style={{ backgroundColor: '#434040', justifyContent: 'flex-end', padding: '0px 50px 0px 50px' }} >
						<Button color="inherit" component={RouterLink} to="/login" className='ToolbarBtnLogin' sx={{ textTransform: "capitalize" }}>
							<img src="images/Login.png" className='ToolbarImg' alt="Company Login button" />
							Company Login
						</Button>
						<a color="inherit" href="mailto:support@geniesign.io" component={RouterLink} className='ToolbarBtnEmail'>
							<img src="images/Email.png" className='ToolbarImg' alt="Company Email button" />
							support@geniesign.io
						</a>
					</Toolbar>
				</AppBar>

				{/*Header Code Reference Navbar.JS */}
				<NavBarHome />
				<div style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-end',
					marginBottom: '5px',
				}}>
					<LanguageSharpIcon sx={{
						color: '#434040',
					}} />

					<button
						className={`English ${rtl === '' ? 'active' : "English"}`}
						onClick={async (e) => {
							e.preventDefault()
							setrtl('')
							lang = 'EN'
							if (v_Email === '') {
								AURL = CURL
							}
							else {
								AURL = v_Email.substring(
									v_Email.indexOf("@") + 1,
									v_Email.lastIndexOf("")
								)
							}
							FetchCNCLURL(AURL)
						}}
					>English</button>

					<Typography sx={{
						color: '#414042',
						fontWeight: '400',
					}}>|</Typography>
					<button
						className={`Arabic ${rtl === 'rtl' ? 'active' : "Arabic"}`}
						onClick={async (e) => {
							e.preventDefault()
							setrtl('rtl')
							lang = 'AR'
							if (v_Email === '') {
								AURL = CURL
							}
							else {
								AURL = v_Email.substring(
									v_Email.indexOf("@") + 1,
									v_Email.lastIndexOf("")
								)
							}
							FetchCNCLURL(AURL)
						}}
					>العربية</button>

				</div>

				{/*Header Code Reference Navbar.JS */}

				<div className="Form-Main">
					<TableContainer component={Paper} className="Container-Main">
						<Table size='small' style={{ marginBottom: '10px', width: '480px' }}>
							<TableBody>
								<TableRow>
									<TableCell style={{ width: 400, borderBottom: "none" }}>
										<Paper elevation={0} className="Style-Heading">
											<Typography className="Typo-Heading">
												Personal Details
											</Typography>
										</Paper>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell style={{ borderBottom: "none" }}>
										<Paper elevation={0} style={{ height: 'auto', width: 400 }} >
											<TextField
												dir={rtl}
												variant="outlined"
												size='small'
												className={
													v_FullName === '' ? 'valid' :
														NameValid === false ? 'invalid' :
															NameLengthValid === false ? 'invalid' : ''
												}
												FormHelperTextProps={{ style: { color: 'red', height: '15px', marginLeft: '0px' } }}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start" >
															<img src="images/FullName.png" alt="FullName field icon" />
														</InputAdornment>
													),

													style: {
														height: '35px',
														width: '400px',
														fontSize: '13px',
														fontFamily: 'Nunito Sans',
													}

												}}
												InputLabelProps={{
													style: {
														fontSize: '12px',
													}
												}}

												type="text"
												label={cookies.c_FullName || "Full Name"}
												value={v_FullName}
												onChange={FullNameChange}
												helperText={
													v_FullName === '' ? "" :
														NameValid === false ? "Full Name cannot contain Special Characters and Numbers" :
															NameLengthValid ? "" : "Full Name cannot contain more than 256 characters"
												}
											>
											</TextField>

										</Paper>
									</TableCell>


									{/*==========================================================================================================================================================*/}
								</TableRow>

								{/*-------------------------------------------------------------------------------------------------------------*/}
								<TableRow>
									<TableCell style={{ borderBottom: "none", }}>
										<Paper elevation={0} style={{ height: 'auto', width: 400, marginTop: 0 }} >
											<TextField
												dir={rtl}
												variant="outlined"
												size='small'
												className={
													v_PositionTitle === '' ? 'valid' :
														PositionTitleValid === false ? 'invalid' : ''
												}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start" >
															<img src="images/Position.png" alt="Position field icon" />
														</InputAdornment>
													),
													style: {
														height: '35px',
														width: '400px',
														fontSize: '13px',
														fontFamily: 'Nunito Sans'
													}
												}}
												InputLabelProps={{
													style: {
														fontSize: '12px',
													}
												}}

												type="text"
												label={cookies.c_PositionTitle || "Position Title"}
												value={v_PositionTitle}
												onChange={PositionTitleChange}
												FormHelperTextProps={{ style: { color: 'red', height: '15px', marginLeft: '0px' } }}
												helperText={
													v_PositionTitle === '' ? "" :
														PositionTitleValid === false ? "Position Title cannot contain Special Characters and Numbers" :
															PositionTitleLengthValid ? "" : "Position Title cannot contain more than 256 characters"
												}
											>
											</TextField>
										</Paper>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ borderBottom: "none" }}>
										<Paper elevation={0} style={{ height: 'auto', width: 400, marginTop: 0 }} >
											<TextField
												dir={rtl}
												variant="outlined"
												size='small'
												className={
													PhoneNumberLengthValid === false ? 'invalid' : ''
												}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start" >
															<img src="images/Telephone.png" alt="Phone field icon" />
														</InputAdornment>
													),
													style: {
														height: '35px',
														width: '400px',
														fontSize: '13px',
														fontFamily: 'Nunito Sans'
													}
												}}
												InputLabelProps={{
													style: {
														fontSize: '12px',
													}
												}}

												type="text"
												label={cookies.c_LineNumber || "Phone Number"}
												value={v_LineNumber}
												onChange={LineNumberChange}
												FormHelperTextProps={{ style: { color: 'red', height: '15px', marginLeft: '0px' } }}
												helperText={
													PhoneNumberLengthValid === false ? "Phone Number cannot contain more than 12 characters" : ''
												}
											>
											</TextField>
										</Paper>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell style={{ borderBottom: "none" }}>
										<Paper elevation={0} style={{ height: 'auto', width: 400, marginTop: 0 }} >
											<TextField
												dir={rtl}
												variant="outlined"
												size='small'
												InputProps={{
													startAdornment: (
														<InputAdornment position="start" >
															<img src="images/Extension_Grey.png" alt="Extension field icon" />
														</InputAdornment>
													),
													style: {
														height: '35px',
														width: '400px',
														fontSize: '13px',
														fontFamily: 'Nunito Sans'
													}
												}}
												InputLabelProps={{
													style: {
														fontSize: '12px',
													}
												}}
												className={ExtensionLengthValid === false ? 'invalid' : ''}
												type="text"
												label={cookies.c_Extension || "Extension"}
												value={v_Extension}
												onChange={ExtensionChange}
												FormHelperTextProps={{ style: { color: 'red', height: '15px', marginLeft: '0px' } }}
												helperText={
													ExtensionLengthValid === false ? "Extension cannot contain more than 5 characters" : ''
												}
											>
											</TextField>
										</Paper>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell style={{ borderBottom: "none" }}>
										<Paper elevation={0} style={{ height: 'auto', width: 400, marginTop: 0 }} >
											<TextField
												dir={rtl}
												variant="outlined"
												size='small'
												InputProps={{
													startAdornment: (
														<InputAdornment position="start" >
															<img src="images/Mobile.png" alt="Mobile field icon" />
														</InputAdornment>
													),
													style: {
														height: '35px',
														width: '400px',
														fontSize: '13px',
														fontFamily: 'Nunito Sans'
													}
												}}
												InputLabelProps={{
													style: {
														fontSize: '12px',
													}
												}}
												className={MobileNumberLengthValid === false ? 'invalid' : ''}
												type="text"
												label={cookies.c_MobileNumber || "Mobile Number"}
												value={v_MobileNumber}
												onChange={MobileNumberChange}
												FormHelperTextProps={{ style: { color: 'red', height: '15px', marginLeft: '0px' } }}
												helperText={
													MobileNumberLengthValid === false ? "Mobile Number cannot contain more than 12 characters" : ''
												}
											>
											</TextField>
										</Paper>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell style={{ borderBottom: "none" }}>
										<Paper elevation={0} style={{ height: 'auto', width: 400, marginTop: 0 }} >
											<TextField
												dir={rtl}
												variant="outlined"
												size='small'
												className={
													(v_Email === '' && 'valid') || (UserEmailValid === false) || (EmailLengthValid === false && 'invalid')
												}
												FormHelperTextProps={{ style: { color: 'red', height: '10px', marginLeft: '0px' } }}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start" >
															<img src="images/Mail.png" alt="EMail field icon" />
														</InputAdornment>
													),
													style: {
														height: '35px',
														width: '400px',
														fontSize: '13px',
														fontFamily: 'Nunito Sans'
													}
												}}
												InputLabelProps={{
													shrink: true,
													style: {
														fontSize: '12px',
													}
												}}

												type="text"
												label={cookies.c_Email || "Email"}
												value={v_Email}
												onInput={EmailChange}
												helperText={
													v_Email === '' ? "" :
														UserEmailValid === false ? "Invalid Email" :
															EmailLengthValid === false ? "Email cannot contain more than 256 characters" : URLMatch === false ? "Please enter your company email" : ""
												}
											>
											</TextField>
										</Paper>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell style={{ borderBottom: "none" }}>
										<Paper elevation={0} className="Style-Heading">
											<Typography className="Typo-Heading">
												Company Details
											</Typography>
										</Paper>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell style={{ borderBottom: "none" }}>
										<Paper elevation={0} style={{ height: 'auto', width: 400, marginTop: 0 }} >
											<TextField
												dir={rtl}
												variant="outlined"
												size='small'
												className={
													(v_CompanyName === '' && 'valid') || (CompanyNameValid === false && 'invalid') || (CompanyNameLengthValid === false && 'invalid')
												}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start" >
															<img src="images/Company.png" alt="Company Name field icon" />
														</InputAdornment>
													),
													style: {
														height: '35px',
														width: '400px',
														fontSize: '13px',
														fontFamily: 'Nunito Sans'
													}
												}}
												InputLabelProps={{
													style: {
														fontSize: '12px',
													}
												}}
												disabled={CURL !== '' && CNCLURLResponse === "Matched"}
												type="text"
												label={cookies.c_CompanyName || "Company Name"}
												value={v_CompanyName}
												onChange={CompanyChange}
												FormHelperTextProps={{ style: { color: 'red', height: '15px', marginLeft: '0px' } }}
												helperText={
													v_CompanyName === '' ? "" :
														CompanyNameValid === false ? "Company Name cannot contain Special Characters" :
															CompanyNameLengthValid === false ? "Company Name cannot contain more than 256 characters" : ""
												}

											>
											</TextField>

										</Paper>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell style={{ borderBottom: "none" }}>
										<Paper elevation={0} style={{ height: 'auto', width: 400, marginTop: 0 }} >
											<TextField
												dir={rtl}
												variant="outlined"
												size='small'
												className={
													FetchingURLValid === true ? 'valid' :
														v_CompanyUrl === '' ? 'valid' :
															URLValid === false ? 'invalid' :
																CompanyUrlLengthValid === false ? 'invalid' : ''

												}
												FormHelperTextProps={{ style: { color: 'red', height: '15px', marginLeft: '0px' } }}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start" >
															<img src="images/Website.png" alt="Company Website field icon" />
														</InputAdornment>
													),
													style: {
														height: '35px',
														width: '400px',
														fontSize: '13px',
														fontFamily: 'Nunito Sans'
													}
												}}
												InputLabelProps={{
													style: {
														fontSize: '12px',
													}
												}}
												disabled={CURL !== '' && CNCLURLResponse === "Matched"}
												type="text"
												label={cookies.c_CompanyUrl || "Company Website"}
												value={v_CompanyUrl}
												onInput={URLChange}
												helperText={
													v_CompanyUrl === '' ? "" :
														FetchingURLValid === true ? "" :
															URLValid === false ? "Invalid URL" :
																CompanyUrlLengthValid === false ? "Company URL cannot contain more than 256 characters" : ""
												}
											>
											</TextField>
										</Paper>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell style={{ borderBottom: "none" }}>
										<Paper elevation={0} style={{ height: 'auto', width: 400, marginTop: 0 }} >
											<TextField
												dir={rtl}
												variant="outlined"
												size='small'
												InputProps={{
													startAdornment: (
														<InputAdornment position="start" >
															<img src="images/Link.png" alt="Company Logo URL field icon" />
														</InputAdornment>
													),
													style: {
														height: '35px',
														width: '400px',
														fontSize: '13px',
														fontFamily: 'Nunito Sans'
													}
												}}
												InputLabelProps={{
													style: {
														fontSize: '12px',
													}
												}}
												className={
													CompanyLogoUrlLengthValid === false ? 'invalid' : ''
												}
												disabled={CURL !== '' && CNCLURLResponse === "Matched"}
												type="text"
												label={"Company Logo Url / Link"}
												value={v_CompanyLogoUrl}
												onChange={
													(event) => {
														set_v_CompanyLogoUrl(event.target.value)
														if (event.target.value.length > 1024) {
															setCompanyLogoUrlLengthValid(false)
														}
														else {
															setCompanyLogoUrlLengthValid(true)
														}
													}
												}
												FormHelperTextProps={{ style: { color: 'red', height: '15px', marginLeft: '0px' } }}
												helperText={
													CompanyLogoUrlLengthValid === false ? "Company Logo Url cannot contain more than 1024 characters" : ""
												}
											>
											</TextField>
										</Paper>
									</TableCell>
								</TableRow>



							</TableBody>
						</Table>
						<Table style={{ width: '400px', height: '527px' }}>
							<TableRow>
								<TableCell rowSpan={7} align='left' style={{ width: 630, height: 200, background: 'white', borderBottom: "none", position: 'relative' }} >


									{
										FetchingURLValid === true && UserEmailValid === true && v_FullName !== '' && v_PositionTitle !== '' && v_MobileNumber !== '' && v_CompanyName !== '' && v_CompanyUrl !== '' && v_CompanyLogoUrl !== '' && NameValid === true && NameLengthValid === true && PositionTitleLengthValid === true && PhoneNumberLengthValid === true && ExtensionLengthValid === true && MobileNumberLengthValid === true && EmailLengthValid === true && CompanyNameLengthValid === true && CompanyUrlLengthValid === true && CompanyLogoUrlLengthValid && URLMatch === true &&

										<Box className="Sign-Btn" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
											<Box sx={{ m: 1, position: 'relative' }}>
												<Fab
													aria-label="save"
													sx={{
														color: '#fff',
														bgcolor: '#414042',
														fontSize: '1rem',
														width: '45px',
														height: '45px',
														'&:hover': {
															color: '#414042',
															bgcolor: '#fff'
														},
														...(success === true && {
															bgcolor: green[500],
															'&:hover': {
																bgcolor: green[700],
															},
														}),
													}}
													onClick={Copy}
												>
													{success ? <CheckIcon /> : <ContentCopyIcon />}
												</Fab>
												{loading && (
													<CircularProgress
														size={56}
														sx={{
															color: '#414042',
															position: 'absolute',
															top: -6,
															left: -6,
															zIndex: 1,
														}}
													/>
												)}
											</Box>
											<Box sx={{ m: 1, position: 'relative' }}>
												<Button
													variant="contained"
													sx={{
														color: '#fff',
														bgcolor: '#414042',
														fontSize: '12px',
														'&:hover': {
															bgcolor: '#fff',
															color: '#414042',
														},
														...(success === true && {
															bgcolor: green[500],
															'&:hover': {
																bgcolor: green[700],
															},
														}),
													}}
													disabled={loading}
													onClick={Copy}
												>
													Copy to clipboard
												</Button>
												{loading && (
													<CircularProgress
														size={24}
														sx={{
															color: '#414042',
															position: 'absolute',
															top: '50%',
															left: '50%',
															marginTop: '-12px',
															marginLeft: '-12px',
														}}
													/>
												)}
											</Box>
										</Box>
									}

									<div style={{ border: '1px solid #ED1C24' }}>
										<table
											dir={rtl}
											id="theSign"
											style={{ height: 250 }}

										>
											<tr id="theSign-row">
											<td style={{ height: '20px' }}>

												<img
													style={{ width: '130px', padding: '0px 20px' }}
													id="iSImage"
													src={v_CompanyLogoUrl || cookies.c_CompanyLogoUrl || 'images/Logo-Placeholder.png'}

												/>


											</td>
												<td dir={rtl} id="sign-details" style={(rtl === 'rtl') ? { paddingLeft: '150px' } : { paddingRight: '150px' }} >
													<table style={rtl ? { borderRight: '2px solid #414042', width: '250px' } : { borderLeft: '2px solid #414042', width: '250px' }}>
														<tr>
															<td colSpan={2} className="Full-Name" style={(rtl === 'rtl') ? { paddingRight: '10px', textAlign: 'right' } : { textAlign: 'left' }}>
																{v_FullName || cookies.c_FullName || "Name"}
															</td>
														</tr>
														<tr>
															<td colSpan={2} className="Position" style={(rtl === 'rtl') ? { paddingRight: '10px', textAlign: 'right' } : { textAlign: 'left' }}>
																{v_PositionTitle || cookies.c_PositionTitle || "Position"}
															</td>
														</tr>
														<tr>
															<td colSpan={2} className="Company" style={(rtl === 'rtl') ? { paddingRight: '10px', textAlign: 'right' } : { paddingLeft: '10px' }}>
																{v_CompanyName || cookies.c_CompanyName || "Company"}
																<div className="Signature-Border" >

																</div>
															</td>
														</tr>
														{
															v_LineNumber !== '' &&
															<tr>
																<td className="Icons" style={(rtl === 'rtl') ? { paddingRight: '10px', float: 'right' } : { paddingLeft: '10px' }}>
																	<img src="images/Sign_Contact.png" style={{ width: 14, height: 14 }} alt="Phone Number Template icon" />
																</td>
																<td className="Details" style={(rtl === 'rtl') ? { paddingRight: '10px', float: 'right' } : { float: 'left' }}>

																	{v_LineNumber || cookies.c_LineNumber}
																	{v_Extension !== '' && " x " +
																		v_Extension
																	}


																</td>
															</tr>
														}

														{
															v_MobileNumber !== '' &&
															<tr>
																<td className="Icons" style={(rtl === 'rtl') ? { paddingRight: '10px', float: 'right' } : { paddingLeft: '10px' }}>
																	<img src="images/Sign_Mobile.png" style={{ width: 14, height: 14 }} alt="Mobile Number Template icon" />
																</td>
																<td className="Details" style={(rtl === 'rtl') ? { paddingRight: '10px', float: 'right' } : { float: 'left' }}>
																	{v_MobileNumber || cookies.c_MobileNumber || "Mobile Number"}
																</td>
															</tr>
														}


														<tr>
															<td className="Icons" style={(rtl === 'rtl') ? { paddingRight: '10px', float: 'right' } : { paddingLeft: '10px' }}>
																<img src="images/Sign_Mail.png" style={{ width: 14, height: 14 }} alt="Email Template icon" />
															</td>
															<td className="Details" style={(rtl === 'rtl') ? { paddingRight: '10px', float: 'right' } : { float: 'left' }}>
																{v_Email || cookies.c_Email || "Email"}
															</td>
														</tr>

														<tr>
															<td className="Icons" style={(rtl === 'rtl') ? { paddingRight: '10px', float: 'right' } : { paddingLeft: '10px' }}>
																<img src="images/Sign_Website.png" style={{ width: 14, height: 14 }} alt="Website URL Template icon" />
															</td>
															<td className="Details" style={(rtl === 'rtl') ? { paddingRight: '10px', float: 'right' } : { float: 'left' }}>
																<a tabIndex="-1" href={"https://" + v_CompanyUrl} rel="noreferrer" target="_blank">{v_CompanyUrl || cookies.c_CompanyUrl || "Website"}</a>
															</td>
														</tr>

													</table>
												</td>
											</tr>
										</table>

									</div>
									{
										FetchingURLValid === true && UserEmailValid === true && v_FullName !== '' && v_PositionTitle !== '' && v_MobileNumber !== '' && v_CompanyName !== '' && v_CompanyUrl !== '' && v_CompanyLogoUrl !== '' && NameValid === true && URLMatch === true &&
										<div style={{ marginTop: '5px', textAlign: 'right', position: 'absolute', bottom: '-6px', right: '13px' }}>
											<Typography className="Details">How to add Email signatures? Click on the icons below </Typography>
											<Tooltip title="Gmail">
												<Link style={{ marginLeft: '4px' }} href="https://geniesign.io/user-guide-gmail/" rel="noopener" target="_blank"><GmailIcon /></Link>
											</Tooltip>
											<Tooltip title="Office 365">
												<Link style={{ marginLeft: '4px' }} href="https://geniesign.io/user-guide-outlook-and-office365/" rel="noopener" target="_blank"><Office365Icon /></Link>
											</Tooltip>
											<Tooltip title="Outlook">
												<Link style={{ marginLeft: '4px' }} href="https://geniesign.io/user-guide-outlook-and-office365/" rel="noopener" target="_blank"><OutlookIcon /></Link>
											</Tooltip>
											<Tooltip title="Apple Mail">
												<Link style={{ marginLeft: '4px' }} href="https://geniesign.io/user-guide-apple-mail/" rel="noopener" target="_blank"><AppleMailIcon /></Link>
											</Tooltip>
										</div>
									}
								</TableCell>
							</TableRow>
						</Table>
					</TableContainer>
				</div>

			</Container>

			{/*Footer Code Reference Footer.js */}
			<Footer />
			{/*Footer Code Reference Footer.js */}

			{/*---Limit Exceed PopUp---*/}

			<div >
				<Dialog onClick={handleClickOpen}
					PaperProps={{
						style: {
							width: '1500px',
							maxHeight: 500,
							backgroundColor: '#FFF'
						}
					}}
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					//aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle className="PopUp-Heading">{"OOPS!"}</DialogTitle>
					<DialogContent style={{ backgroundColor: '#FFF' }}>
						<DialogContentText className="PopUp-Text">
							Your organisation has reached the limit of 5 free email signatures. Let us request IT Manager/CIO
							of {v_CompanyName} to signup and help you create your own email signature.
						</DialogContentText>
					</DialogContent>
					<TableRow style={{ backgroundColor: '#FFF' }}>
						<TableCell style={{ borderBottom: "none" }}>
							<Paper elevation={0} style={{ height: 35, width: 400, marginTop: 0, backgroundColor: '#FFF', marginLeft: '80px' }} >
								<TextField
									variant="outlined"
									size='small'
									className={ITEmailValid === false && 'invalid'}
									InputProps={{
										style: {
											height: '35px',
											width: '400px',
											fontSize: '13px',
											fontFamily: 'Nunito Sans'
										}
									}}
									InputLabelProps={{
										shrink: true,
										style: {
											fontSize: '12px',
										}
									}}

									type="text"
									label={"CIO / IT Manager Email"}
									value={v_ITManagerEmail}
									onChange={ITEmailChange}
									helperText={
										ITEmailValid === false ? "Invalid Email" : ""
									}
								>
								</TextField>

							</Paper>
						</TableCell>
					</TableRow>
					<DialogActions style={{ backgroundColor: '#FFF', padding: 20 }}>
						<Button className="PopUp-Btn-Close" onClick={handleClose}>
							Close
						</Button>
						<Button
							disabled=
							{
								v_ITManagerEmail === '' || ITEmailValid === false
							}
							onClick={EmailSent} className="PopUp-Btn">
							Send Request
						</Button>


					</DialogActions>
				</Dialog>
				<Snackbar open={openConfirmation} autoHideDuration={5000} onClose={handleConfirmationClose}>
					<Alert onClose={handleConfirmationClose} severity="success" sx={{ width: '100%' }}>
						Email has been Sent.
					</Alert>
				</Snackbar>
			</div>

			{/*---Limit Exceed PopUp---*/}
		</div>


	);

}
export default Home;
