import React from 'react'
import Backdrop from '@mui/material/Backdrop';
const LoadingBackdrop = (props) => {
    return (
        <Backdrop
            sx={
                {
                    color: '#fff',
                    backdropFilter: 'blur(7px)',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }
            }
            open={props.open}
        >
            {props.icon}
        </Backdrop>
    )
}

export default LoadingBackdrop