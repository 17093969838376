import { React, useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom';

import { Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import classes from './MiniProfile.module.css';

const NavItem = (props) => {
    return (
        <li className="profile-submenu-item">
            <NavLink to={props.to} className="profile-submenu-icon-button" onClick={
                (event) => {
                    event.stopPropagation();
                    localStorage.clear("token");

                }}>
                <div className={`${classes.iconbutton} abc`}>{props.icon}</div>{props.title}
            </NavLink>
        </li>
    );
};
const profile_menu = (event) => {
    return (
        <ul className={`${classes.profilesubmenu}`}>
            <NavItem icon={<LogoutIcon />} title="Logout" to="/login" />
        </ul>
    );
};
function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(
        initialIsVisible
    );
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
        if (event.key === "Escape") {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event) => {
        setIsComponentVisible(false);
    };

    useEffect(() => {
        document.addEventListener("keydown", handleHideDropdown, true);
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("keydown", handleHideDropdown, true);
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}

const MiniProfile = (props) => {
    useEffect(() => {
        props.setrefprop(ref);
    });
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);
    
    return (        
        <div className={classes.miniprofile}>
            <Typography mt={2} mb={1} variant="h5" component="h1" >
                {props.AdminName}
            </Typography>
            <div style={{
                position: 'relative',
            }}>
                <img
                    src={window.location.origin + "/images/profile-avatar.png"}
                    className={`${classes.profileavatar}`}
                    onClick={() => {
                        setIsComponentVisible(true)
                    }}
                />
                {/* {isProfileMenuOpen ? profile_menu() : ''} */}
                {isComponentVisible && profile_menu()}

            </div>
        </div>
    )
}

export default MiniProfile