import { Link, useNavigate } from 'react-router-dom';
import "./App.css";
import NavBar from './NavBar';

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import URL from './config.js'
import { InputAdornment } from '@mui/material';
import Footer from './Footer.js';
import {Container} from '@mui/material';

const SetPassword = () => {

    const [NewPassEmail, setNewPassEmail] = useState('')
    const [emailadd, setemailadd] = useSearchParams();
    const [v_NewPassword, set_v_NewPassword] = React.useState('');
    const [NewPassValid, setNewPassValid] = useState(null);
    const [v_confirmNewPassword, set_v_ConfirmNewPassword] = React.useState('');
    const [ConfirmNewPassValid, setConfirmNewPassValid] = useState(null);
    
    const navigate = useNavigate();

    useEffect(async () => {
        document.title = "Set your GenieSign password - Email Signature Generator";
        if(emailadd.get('e') !== null && emailadd.get('c') !== null){
        const response = await URL.post('/decryptcode',
            {
                u_EncryptKeyIV: emailadd.get('e'),
                u_EncryptKeyContent: emailadd.get('c')
            });
            setNewPassEmail(response.data);
        }
    }, [])


    {/*-------- Password Save to DB ------------*/ }

    const PasstoDB = async () => {
        const result = await URL.post('/PassSaved',
            {
                u_PasswordEmail: NewPassEmail,
                u_Password: v_NewPassword,
            });
            navigate(`/login`)
    }

    {/*-------- Password Save to DB ------------*/ }

    {/*-------- Password Validation Start------------*/ }

    const NewPassChange = (e) => {
        const { value } = e.target;
        set_v_NewPassword(value);

    };

    const validatePass = (e) => {
        const regEx = new RegExp(/^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);
        return regEx.test(e);
    };
    const PassHightlight = (e) => {
        const PassValidation = validatePass(e.target.value);
        setNewPassValid(PassValidation);
    }

    {/*-------- Password Validation End------------*/ }

    {/*-------- Re - Password Validation Start------------*/ }


    const ConfirmPassChange = (e) => {
        const { value } = e.target;
        set_v_ConfirmNewPassword(value);
    };

    const validateConfirmPass = (e) => {
        const regEx = new RegExp(/^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);
        return regEx.test(e);
    };

    const ConfirmPassHightlight = (e) => {

        const ConfirmPassValidation = validateConfirmPass(e.target.value);
        setConfirmNewPassValid(ConfirmPassValidation);
    }

    {/*-------- Re - Password Validation End------------*/ }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Container className="page-container">
                <AppBar position="absolute">
                <Toolbar variant="dense" style={{ backgroundColor: '#434040' }} >

                    <a style={{ marginLeft: '1300px' }} href="mailto:support@geniesign.io" color="inherit" component={Link} to="/signup" class='ToolbarBtn'>
                        <img src="images/Email.png" className='ToolbarImg' />
                        support@geniesign.io
                    </a>
                </Toolbar>
            </AppBar>

            {/*Header Code Reference Navbar.JS */}
            <NavBar />
            {/*Header Code Reference Navbar.JS */}

            <div className="SP-Form-Main">
                <Typography className="SP-Container-Main">
                    Reset Password
                </Typography>
            </div>

            <Paper className="SP-Center-TextField" elevation={0} style={{ height: 50, width: 200, marginTop: 20 }}  ></Paper>
            <div className="SP-Center-Main">
                <Paper className="SP-Center-TextField" elevation={0} style={{ height: 50, width: 200, marginTop: 0 }}  ></Paper>
                <TextField
                    variant="outlined"
                    size='small'
                    FormHelperTextProps={{style: {color: 'red', height: '50px', marginLeft: '0px'}}}
                    InputProps={{
                        endAdornment: (

                            <InputAdornment position="start" >
                                {
                                    NewPassValid === null || v_NewPassword === '' ? null
                                        : NewPassValid === true ?
                                            <img src="images/tick.png" style={{ width: 20 }} />
                                            : <img src="images/wrong.png" style={{ width: 16 }} />


                                }

                            </InputAdornment>
                        ),
                        style: {
                            height: '40px',
                            width: '100%',
                            fontSize: '12pt'
                        }
                    }}
                    InputLabelProps={{

                        style: {
                            fontSize: '11pt',
                            verticalAlign: 'center'
                        }
                    }}


                    type="password"
                    label={"New Password"}
                    value={v_NewPassword}
                    onChange={NewPassChange}
                    onInput={PassHightlight}
                    helperText={
                        v_NewPassword === ''? null
                        :NewPassValid === true?
                        ""
                        : "Must contain 1 uppercase,1 lowercase, 1 special character, 1 digit and 8 characters long"
                    }
                >

                </TextField>


                <Paper className="SP-Center-TextField" elevation={0} style={{ height: 50, width: 200, marginTop: 0 }}  ></Paper>
                <TextField
                    variant="outlined"
                    size='small'
                    FormHelperTextProps={{style: {color: 'red', height: 'auto', marginLeft: '0px'}}}
                    InputProps={{
                        
                        style: {
                            height: '40px',
                            width: '100%',
                            fontSize: '12pt',
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            fontSize: '11pt',
                            verticalAlign: 'center'
                        }
                    }}


                    type="password"
                    label={"Confirm New Password"}
                    value={v_confirmNewPassword}
                    onChange={ConfirmPassChange}
                    onInput={ConfirmPassHightlight}
                    helperText={
                        v_NewPassword === '' || v_confirmNewPassword === ''? null
                        :v_confirmNewPassword !== '' && v_confirmNewPassword === v_NewPassword?
                        ""
                        : "Passwords don’t match"
                    }

                >

                </TextField>


                <Button className='SP-Btn'
                    disabled={
                        v_NewPassword === '' || v_confirmNewPassword === '' || (v_confirmNewPassword !== v_NewPassword) || NewPassValid !== true || ConfirmNewPassValid !== true
                    } onClick={PasstoDB}>
                    Save Password
                </Button>
            </div>
            </Container>
            {/*Footer Code Reference Footer.js */}
			<Footer />
			{/*Footer Code Reference Footer.js */}
        </div>
    );
}
export default SetPassword;