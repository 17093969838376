import React, { useEffect, useState } from 'react';
import URL from './config.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingLinearBackdrop from './Components/LoadingLinearBackdrop/LoadingLinearBackdrop.js';

function Loading(){
    // const [ProgressValue, SetProgressValue] = useState();
    const [sessionid, set_SessionID] = useSearchParams();
    let ProgressValue;
    let paymentStatus = null;
    let loadingEmail = null;
    let SubscriptionID = null

    const getItems = () => {
        return new Promise((resolve, reject) => {
            setTimeout(async() => {
                 const response = await URL.post('/fetchSessionInfo',
                 {
                    u_SessionID: sessionid.get('session_id'),
                 })
                    paymentStatus = response.data.Payment_Status;
                    loadingEmail = response.data.Email
                    SubscriptionID = response.data.SubID
                    resolve(paymentStatus)
                    resolve(loadingEmail)
                    resolve(SubscriptionID)  
            },[])
            }, 2000)
        }
    
    const navigate = useNavigate();
    let responseMsg, encryptEmailIV, encryptEmailContent, pstatus;
    
    useEffect(async () => {
        document.title = "Moving to Dashboard of GenieSign - Signature Generator";
        const PaymentStatusValue = await getItems();
        const response = await URL.post('/UpdateCompany',
            {
                u_CusPaymentStatus: PaymentStatusValue,
                u_CusEmail: loadingEmail,
                u_SubID: SubscriptionID
            });
            encryptEmailIV = response.data.LEmail.iv;
            encryptEmailContent = response.data.LEmail.content;
            responseMsg = response.data.Message;
            pstatus = response.data.pstatus;
        }, [ProgressValue])
        
        const progressfunc = (value) => {
            if(responseMsg === "Updated" && value === 110)
            {
                navigate(`/Dashboard?e=${encryptEmailIV}&c=${encryptEmailContent}&p=${pstatus}`);               
            }
        }
        return (
        <div className='loading-main'>
            <div className='loading-main-inner'>
                <LoadingLinearBackdrop progress={progressfunc}/>
                <p>Please wait! You are being redirected to GenieSign.</p>
            </div>
        </div>        
    );
}
export default Loading;