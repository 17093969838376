import React from 'react'
import Button from '@mui/material/Button'
import classes from './SpinnerButton.module.css'
import CircularProgress from '@mui/material/CircularProgress';

const SpinnerButton = (props) => {

    return(
        <Button className={`${classes.LGBtn}`} disabled={props.loading || props.fempty}
                     onClick={props.fg}>
                    {props.text}
                    {props.loading && (
					              <CircularProgress
						            size={25}
						            sx={{
							          color: '#414042',
							          position: 'absolute',
							          top: '20%',
							          right: '45%',
							          zIndex: 1,
						        }}
					       />
				        )}
                </Button>

    );

}
export default SpinnerButton;