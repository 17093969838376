import React from 'react';
import PinInput from 'react-pin-input';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import "./App.css";
import NavBar from './NavBar';
import URL from './config.js'
import Footer from './Footer.js'
import ValidateButton from './Components/SpinnerButton/SpinnerButton.js'

import { Button, Typography, Paper, AppBar, Toolbar, Container } from '@mui/material'

const ValidateEmailAddress = () => {

    const [v_code, set_v_code] = useState("");
    const [v_error, set_v_error] = useState("");
    const [emailadd, setemailadd] = useSearchParams();
    const [UserEmail, setUserEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(async () => {
        document.title = "Validate your email - GenieSign - Email Signature Generator";
        if(emailadd.get('e') !== null && emailadd.get('c') !== null){
        const response = await URL.post('/decryptcode',
            {
                u_EncryptKeyIV: emailadd.get('e'),
                u_EncryptKeyContent: emailadd.get('c')
            });
        setUserEmail(response.data);
    }
    }, [])

    const handleChange = (value, index) => {
        set_v_code(value);
    }

    const CodeVerified = async () => {
        setLoading(true)
        const result = await URL.post('/codeverification',
            {
                u_VerifyEmail: UserEmail,
                u_UserCode: v_code
            });
        let codeencryptEmailIV = result.data.codeverifiedemail.iv
        let codeencryptEmailContent = result.data.codeverifiedemail.content
        if (result.data.message === 'Verified') {
            setLoading(false)
            navigate(`/setpassword?e=${codeencryptEmailIV}&c=${codeencryptEmailContent}`)
        }
        else {
            set_v_error("Code not match please enter validate code or open the link from Email!")
            setLoading(false)
        }
    }


    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Container className="page-container">
                
                <AppBar position="absolute">
                    <Toolbar variant="dense" style={{ backgroundColor: '#434040', justifyContent: 'flex-end', padding:'0px 50px 0px 50px'  }} >
                        <a color="inherit" href="mailto:support@geniesign.io" component={Link} class='ToolbarBtnEmail'>
                            <img src="images/Email.png" className='ToolbarImg' />
                            support@geniesign.io
                        </a>
                    </Toolbar>
                </AppBar>

                {/*Header Code Reference Navbar.JS */}
                <NavBar />
                {/*Header Code Reference Navbar.JS */}

                <div className="Validate-Form-Main">
                    <Typography class="Validate-Container-Main">
                        Validate your Email Address
                    </Typography>
                    <Typography class="Validate-Container-Text">
                        We have sent code to your email address:<br />
                        {emailadd.get('email')}
                    </Typography>
                </div>

                <div className="Validate-Center-Main">
                    <Paper className="CYM-Center-CodeInput" elevation={0} ></Paper>
                    <PinInput
                        length={6}
                        initialValue=""
                        onComplete={handleChange}
                        onChange={handleChange}
                        type="numeric"
                        inputMode="number"
                        inputStyle={{ borderColor: '#e8e8e8' }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                    />
                    <ValidateButton fg={CodeVerified} loading={loading} text={'Validate'}/>
                    {/* <Button class='Validate-Btn' onClick={CodeVerified}>
                        Validate
                    </Button> */}
                    {v_error && <Typography class="Validate-Container-Text">
                        {v_error}
                    </Typography>}
                </div>
            </Container>
            {/*Footer Code Reference Footer.js */}
            <Footer />
            {/*Footer Code Reference Footer.js */}
        </div>
    
    );
}


export default ValidateEmailAddress;