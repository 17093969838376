import { React, useState, useEffect } from 'react';
import { useSearchParams, Outlet, useNavigate, NavLink } from 'react-router-dom';
import { Box, Button, Alert, Link, Typography } from '@mui/material';


import URL from './config.js'
import Footer from './Footer.js'
import Sidebar from './Sidebar';
import MiniProfile from './Components/MiniProfile/MiniProfile.js';


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', backgroundColor: 'Yellow' }}
    >
        •
    </Box>
);

function Dashboard () {

    const [DashboardEmail, setDashboardEmail] = useState('');
    const [AdminName, SetAdminName] = useState();
    const [Ref, SetRef] = useState();
    const [emailadd, setemailadd] = useSearchParams();
    let paymentValue;
    let e;
    let c;
    const navigate = useNavigate();
    const localstoragetoken = localStorage.getItem("token");

    
    useEffect(async () => {
        document.title = "Dashboard of GenieSign - Email Signature Generator";
        if(localstoragetoken !== null && emailadd.get('e') !== null && emailadd.get('c') !== null){
            const response = await URL.post('/decryptcode',
            {
                u_EncryptKeyIV: emailadd.get('e'),
                u_EncryptKeyContent: emailadd.get('c'),

            });
            setDashboardEmail(response.data);
         
        }
        else{
            navigate("/login");
        }
    }, [])

    paymentValue = emailadd.get('p')
    e=emailadd.get('e')
    c=emailadd.get('c')

    const integration = async () => {
        try {
            const result = await URL.post('/subs',
                {
                    u_SubEmail: DashboardEmail,
                    token: localstoragetoken,
                    u_DashboardEncryptKeyIV: emailadd.get('e'),
                    u_DashboardEncryptKeyContent: emailadd.get('c'),
                    Payment: emailadd.get('p')
                })
            window.location.replace(result.data.url);
        }
        catch (error) {
            navigate("/login");
        }
    }

    useEffect(async () => {
        let AdminNameResponse = await URL.post('/fetchAdminName',
            {
                u_AdminEmail: DashboardEmail,
            }
        );
        SetAdminName(AdminNameResponse.data.q_AdminName)        
    }, [DashboardEmail])

    const setreffunc = (refdata) => {
        SetRef(refdata);
    }

    return (
        <div ref={Ref}>
            {
                !paymentValue ? <Alert className="alert-top" severity="warning" >
                    {/* Your payment has not been set up, please click <Button onClick={integration}>here </Button>to set up payment */}
                    Your Billing Information is not set up. Please click <Link underline="none" sx={{ cursor: 'pointer' }} onClick={integration} component={Link}>here</Link> to set up your billing information.
                </Alert>
                    : null
            }
            <div className='dashboardPage'>
                <div className='dashboardPage-left'>
                    <div className='sidebarMain'>
                        <Sidebar stripe_email={DashboardEmail} e={e} c={c} p={paymentValue}/>
                    </div>
                </div>
                <div className='dashboardPage-right'>
                    <div className='dashboardPage-right-inner'>
                        <MiniProfile AdminName={AdminName} setrefprop={setreffunc} />
                        <Outlet />
                    </div>                    
                </div>
            </div>


            {/*Footer Code Reference Footer.js */}
            <Footer />
            {/*Footer Code Reference Footer.js */}
        </div>
    );
}

export default Dashboard;
